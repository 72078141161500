$light-grey: #c9c6c6;

.slider-modal{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .slider-modal-content{
    width: 60%;
    position: relative;
    .dialog-closing-btn{
      background-color: transparent;
      color: $light-grey;
      position: absolute;
      top: -40px;
      right: -90px;
      i{
        font-size:60px;
      }
    }
    .slick-list{
      .slick-slide{

        .img-content{
          width: 100%;
          padding-bottom: 56.25%;
          position: relative;
          img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
            //max-height: 370px;
          }
        }
      }
    }
    .slider-right-arrow, .slider-left-arrow{
      position: absolute;
      top: calc(50% - 20.5px);

    }
    .slider-right-arrow{
      right: -90px;

    }
    .slider-left-arrow{
      left: -90px;
    }
  }
}

.slider-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  bottom: 0;
  background: rgba(0,0,0,.35);
}
@media all and (max-width: 800px){
  .slider-modal{
    .slider-modal-content{
      width: 70%;
      margin-top: 50px;
      .dialog-closing-btn{
        top: -45px;
        img{
          width: 25px;
        }
      }
      .slider-right-arrow, .slider-left-arrow{
        //top: calc(50% - 32.5px);
        img{
          width: 20px;
        }
      }
      .slider-right-arrow{

      }
      .slider-left-arrow{

      }
    }
  }
}
@media all and (max-width: 600px){
  .slider-modal{
    .slider-modal-content{
      width: 100%;
      .dialog-closing-btn{
        right: 10px;

      }
      .slider-right-arrow, .slider-left-arrow{
        top: calc(50% - 30px);

      }
      .slider-right-arrow{
        right: 10px;
      }
      .slider-left-arrow{
        left: 10px;
        z-index: 1;
      }
    }
  }
}