@import "../mainColors";
$light-grey: #c9c6c6;

.institution-details {
  width: 90%;
  margin: 50px auto;
  min-height: calc(100vh - 195px);
  .institution-header {
    display: flex;
    align-items: flex-end;
    min-height: 200px;

    .img-content {
      width: 570px;

      .img-wrapper{
        position: relative;
        width: 100%;
        padding-bottom: 80%;
        img{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: cover;
        }
      }
    }
    .wrapper{
      margin-left: 70px;

      .institution-title {
        font-family: Bold;
        //margin-bottom: 50px;
        color: $titleColor;
      }
      .institution-profession{
        font-size: 21px;
        font-family: Regular;
        width: 70%;
        line-height: 30px;
      }
    }
  }

  .institution-content {
    font-size: 15px;
    font-family: Regular;
    margin-top: 50px;
  }
}

@media all and (min-width: 1600px){
  .institution-details{
    max-width: 1600px;
  }
}

@media all and (max-width: 1300px) {
  .institution-details {
    .institution-header {
      img {
        max-width: 470px;
      }
      .wrapper{
        margin-left: 40px;

        .institution-title {
        }
        .institution-profession{
          font-size: 18px;
          width: 85%;
        }
      }
    }
    .institution-content {
      font-size: 14px;
    }
  }
}
@media all and (max-width: 1000px) {
  .institution-details {
    .institution-header {
      img {
        max-width: 320px;
      }
      .wrapper{
        margin-left: 10px;

        .institution-title {
          margin-bottom: 35px;
          font-size: 22px;
        }
        .institution-profession{
          font-size: 15px;
          width: 90%;
          line-height: 30px;
        }
      }
    }
  }
}
@media all and (max-width: 768px) {
  .institution-details {
    .institution-header {
      flex-wrap: wrap;
      img {
        max-width: 100%;
      }
      .wrapper{
        margin-left: 0;
        margin-top: 30px;

        .institution-title {
        }
        .institution-profession{
          font-size: 16px;
          width: 70%;
          line-height: 30px;
        }
      }
    }
    .institution-content {
      font-size: 13px;
    }
  }
}
@media all and (max-width:  576px) {
  .institution-details {
    .institution-header {
      img {
      }
      .wrapper{
        .institution-title {
          margin-bottom: 35px;
          font-size: 22px;
        }
        .institution-profession{
          font-size: 16px;
          width: 100%;
          line-height: 30px;
        }
      }
    }
    .institution-content {
      font-size: 13px;
    }
  }
}
