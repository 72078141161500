@import "../mainColors";

.news-page{
  width: 90%;
  margin: 50px auto;

  .ant-dropdown-link{
    font-size: 30px;
    font-family: Bold;
    color: $titleColor;
    border-bottom: 3px solid #7295fe;
    margin-bottom: 35px;
    padding-bottom: 7px;
    display: inline-block;
  }

  .news-content{
    display: flex;
    //justify-content: space-between;
    flex-wrap: wrap;
    min-height: 500px;
  }
}

@media all and (min-width: 1600px){
  .news-page{
    max-width: 1600px;
  }
}

@media all and (max-width: 576px) {
  .news-page{
    .news-content{
      justify-content: center;
    }
  }
}