@import "./mainColors";

$blue-color: #7295fe;

.news-card-home {
  width: 25%;
  margin-bottom: 20px;

  .inner-part {

    border-radius: 6px;
    box-shadow: 0px 0px 8px #88888882;
    margin-right: 15px;

    &:hover {
      box-shadow: 0px 0px 25px #88888882;
      transition: all 0.6s;
    }


    .news-image {
      width: 100%;
      padding-top: 56.25%;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px 6px 0 0;
      }
    }

    .card-content {
      padding: 1rem;
      letter-spacing: 0.5px;

      .categories {
        height: 46px;
        overflow: hidden;
        margin: 10px 0;

        button {
          background-color: $blue-color;
          color: white;
          border: 0;
          border-radius: 15px;
          padding: 8px 15px 7px 15px;
          font-size: 11px;
          margin: 0 5px 15px 0;
          white-space: nowrap;
        }
      }

      .card-title {
        font-size: 16px;
        font-family: Medium;
        line-height: 22px;
        height: 46px;
        overflow: hidden;
        margin-bottom: 12px;
        color: $titleColor;
      }

      .card-description {
        font-size: 13px;
        height: 60px;
        line-height: 20px;
        overflow: hidden;
        margin-bottom: 16px;

        figure {
          display: none;
        }

        span, p {
          margin: 0;
          font-size: 13px !important;
          font-family: Regular !important;
          word-break: break-word;
          line-height: 21px !important;
        }
      }

      .card-footer {
        color: grey;

        .footer-calendar {
          width: 24px;
        }

        small {
          padding-left: 10px;
          text-transform: capitalize;
        }

        .footer-angel {
          float: right;
          cursor: pointer;
          width: 24px;
        }
      }
    }
  }
}

@media all and(max-width: 1300px) and (min-width: 992px) {
  .news-card-home {
    .card-content {
      .categories {
        button {
          font-size: 9px;
        }
      }
    }
  }
}

@media all and (max-width: 992px) {
  .news-card-home {
    width: calc(100% / 3);

    .card-content {
      button {
        padding: 6px;
        font-size: 10px;
        margin-top: 0;
      }

      .card-title {
        font-size: 14px;
      }

      p {
        font-size: 11px;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .news-card-home {
    width: 50%;
  }
}

@media all and (max-width: 576px) {
  .news-card-home {
    width: 400px;
    margin-right: 0;
  }
}
