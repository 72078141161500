@import "../mainColors";
$blue-color: #7295fe;

.page-header{
  margin-bottom: 50px;
  overflow: hidden;
  .category-title{
    color: $titleColor;
    font-size: 30px;
    display: inline-block;
    border-bottom: 3px solid $blue-color;
    margin-bottom: 35px;
    padding-bottom: 7px;
    font-family: Bold;
  }
}

@media all and (max-width: 992px) {
  .page-header{
  }
}
@media all and (max-width: 576px) {
  .page-header{
  }
}