@import "../mainColors";

$light-grey: #c9c6c6;
$blue-color: #7295fe;
.news-details {
  width: 90%;
  padding-top: 50px;
  margin: auto;
  min-height: calc(100vh - 195px);

  .news-spin {
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50vh - 50px);
  }

  .news-header {
    display: flex;
    //align-items: flex-end;
    //transition-duration: 3s;
    .main-img {
      width: 550px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    .video-clip {
      width: 570px;

      .video-wrapper {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;

        .video-youtube {
          position: absolute;
        }
      }
    }

    .news-title-content {
      width: calc(100% - 550px);
      padding-left: 50px;

      .news-title {
        font-size: 25px;
        font-family: Bold;
        color: $titleColor;
      }

      .categories {
        margin-top: 20px;
        //height: 45px;
        //overflow: hidden;
        button {
          background-color: $blue-color;
          color: white;
          border: 0;
          border-radius: 15px;
          padding: 8px 15px 7px 15px;
          font-size: 11px;
          margin: 0 5px 15px 0;
          white-space: nowrap;
        }
      }
    }
  }

  .news-content {
    font-size: 15px;
    font-family: Regular;
    margin-top: 50px;
  }

  .share-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 8px;

/*    .fb-btn {
      background: #1877f2;
      border-radius: 4px;
      height: 28px;
      padding: 0 8px;
      margin-right: 10px;

      span {
        font-size: 13px;
        color: #fff;
        padding-right: 4px;
        padding-left: 2px;
        font-weight: bold;
      }
    }*/

    .like-button {
      position: relative;

      .like {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -11;
        opacity: 1;
      }
    }

  }

  .news-calendar {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    margin: 5px 20px 5px 0;

    img {
      margin-right: 10px;
    }

  }

  .news-slider {
    margin: 40px 0;

    &:hover {
      .slick-next:before, .slick-prev:before {
        color: black !important;
      }
    }

    .slick-prev {
      left: -15px;
    }

    .slick-next {
      right: -15px;
    }

    .slider-item {
      padding-right: 20px;
      max-height: 200px;

    }

    .slick-list {
      margin: 0 10px;

      .slick-track {

      }
    }

    .slick-slide {
      overflow: hidden;
      padding-right: 10px;

      .slider-item {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          position: absolute;
          display: block;
          border-radius: 4px;
        }
      }
    }
  }

  .more-news {
    border-top: 1px solid $light-grey;
    padding: 50px 0;

    h2 {
      color: $titleColor;
      font-family: Bold;
      font-size: 22px;
      margin-bottom: 20px;
    }

    .content {
      display: flex;
      //justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}

@media all and (min-width: 1600px) {
  .news-details {
    max-width: 1600px;
  }
}

@media all and (min-width: 1450px) {
  .news-details {
    .news-header {
      .img-content {
        //width: 40%;
      }
    }
  }
}

@media all and (max-width: 1300px) {
  .news-details {
    .news-header {
      .main-img {
        width: 470px;
      }

      .video-clip {
        width: 470px;
      }

      .news-title-content {
        width: calc(100% - 470px);

        .news-title {
          font-size: 22px;
        }
      }
    }

    .news-content {
      font-size: 14px;
    }
  }
}

@media all and (max-width: 1000px) {
  .news-details {
    .news-header {
      .main-img {
        width: 350px;
      }

      .video-clip {
        width: 350px;
      }

      .news-title-content {
        width: calc(100% - 350px);
        padding-left: 30px;

        .news-title {
          font-size: 20px;
        }
      }
    }

    .news-slider {
      .slick-slide {

        img {
          //height: 130px;
        }
      }
    }
  }
}

@media all and (max-width: 850px) {
  .news-details {
    .news-header {
      .main-img {
        width: 300px;
      }

      .news-title-content {
        width: calc(100% - 300px);

        .news-title {
          font-size: 18px;
        }
      }
    }

    .news-content {
      font-size: 13px;
    }

    .more-news {

    }
  }
}

@media all and (max-width: 750px) {
  .news-details {
    .news-header {
      flex-wrap: wrap;
      //justify-content: center;
      .main-img {
        width: 100%;
      }

      .video-clip {
        width: 100%;
      }

      .news-title-content {
        width: 100%;
        padding-left: 0;

        .news-title {
          font-size: 18px;
          margin: 20px 0 0 0;
        }
      }
    }

    .news-content {
      font-size: 13px;
    }

    .news-slider {
      .slider-item {
        padding-right: 10px;
      }
    }

    .more-news {
      h2 {
      }

      .content {
        justify-content: center;
      }
    }
  }
}
