.video-page{
  width: 90%;
  margin: auto;
  padding-top: 50px;
  min-height: calc(100vh - 195px);
  .video-content{
    display: flex;
    //justify-content: space-between;
    flex-wrap: wrap;
  }
}

@media all and (min-width: 1600px){
  .video-page{
    max-width: 1600px;
  }
}

@media all and (max-width: 576px) {
  .video-page{
    .video-content{
      justify-content: center;
    }
  }
}