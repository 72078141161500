@import "./assets/styles/font";
@import "./assets/styles/mainColors";

* {
  box-sizing: border-box;
  margin: 0;
  font-family: "Regular", 'Montserrat', 'Helvetica', 'Arial', sans-serif;
}

body {

  &::-webkit-scrollbar {
    width: 6px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    // background: #adadad;
    background: #7697FF;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    // background: #757575;
    background: darken(#7697FF, 7%);
    cursor: pointer;
  }

}

//
.attention {
  position: fixed;
  bottom: 100px;
  right: 0;
  z-index: 999999999;

  a {
    cursor: pointer;

    img {
      @media screen and (max-width: 768px) {
        width: 200px;
      }
    }
  }
}

body.ReactModal__Body--open {
  overflow: hidden;
  padding-right: 5px;
}

button {
  cursor: pointer;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
}

.slick-slide {
  outline: none !important;
}

ul {
  //list-style: none;
  //padding-left: 0;
  //margin-bottom: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: white !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms linear;
}

.ReactModal__Overlay--after-open {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  bottom: 0;
  background: rgba(0, 0, 0, .35) !important;
  opacity: 1;

  .preview-modal-content {
    overflow: hidden !important;
  }

}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.home-design-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.gallery-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  bottom: 0;
  background-color: rgba(12, 15, 19, .8);
}

.custom-scrollbar-x {
  &::-webkit-scrollbar {
    height: 3px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: #adadad;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
    cursor: pointer;
  }
}

.custom-scrollbar-y {
  &::-webkit-scrollbar {
    width: 3px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: #adadad;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
    cursor: pointer;
  }
}

.ant-tabs-bar {
  border-bottom: 1px solid #d9d9d9;
}

.ant-tabs.ant-tabs-card {
  .ant-tabs-card-bar {
    .ant-tabs-tab {
      font-weight: 500;
    }

    .ant-tabs-tab-active {
      border-bottom: 1px solid #fff;
    }
  }
}

.homepage {
  .content-wrapper {
    max-width: 1600px;
    margin: auto;
  }
}

@media all and (max-width: 1600px) {
  .homepage {
    .content-wrapper {
      max-width: 90%;
    }
  }
}

:root {
  --ck-image-style-spacing: 1.5em;
}


.ck-content-wrapper {
  figure {
    display: inline !important;
  }

  &::after {
    content: "";
    clear: both;
    display: table;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 400px;
    max-width: 400px;
  }

  p {
    word-break: break-word;
  }

  & .image-style-side,
  & .image-style-align-left,
  & .image-style-align-center,
  & .image-style-align-right {
    max-width: 50%;
  }

  & .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  & .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }

  & .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  & .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  table {
    figure {
      display: block !important;
    }
  }
}

.editor-data {
  .table {
    width: 100%;

    table {
      width: 100%;
      table-layout: fixed;

      p {
        margin: 0;
      }

      thead {
        border: 1px solid #E5E5E6;
        border-radius: 15px 15px 0 0;

        tr {
          th {
            border: 1px solid #E5E5E6;
            padding: 10px;
          }
        }
      }

      tr {
        width: 100%;

        td {
          padding: 10px;
          height: 100%;
          border: 1px solid $borderColor;

          //span {
          //  word-break: break-word;
          //  display: inline-block;
          //  width: 100%;
          //  height: 100%;
          //  overflow: hidden;
          //}
        }
      }
    }
  }
}