$dark: #272727;
$light-grey: #c9c6c6;
@import '../mainColors';

.custom-page {
  width: 90%;
  margin: 50px auto;
  min-height: calc(100vh - 195px);

  .page-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .image-wrapper {
      //display: flex;
      //align-items: center;
      //justify-content: center;
      margin-bottom: 20px;

      img {
        max-width: 400px;
        height: auto;
        object-fit: cover;
      }
    }


  }
  .media-wrapper{
    display: flex;
    flex-wrap: wrap;
    .media-item{
      width: 20%;
      padding-right: 20px;
      padding-bottom: 20px;

      .image{
        padding-bottom: 56.25%;
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        padding-right: 20px;
      }
      img{
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 4px;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media all and (min-width: 1600px) {
  .custom-page {
    max-width: 1600px;
  }
}

@media all and (max-width: 1100px) {
  .custom-page {
    .page-content{
      .image-wrapper{}
    }
    .media-wrapper{
      .media-item{
        width: 25%;
      }
    }
  }
}
@media all and (max-width: 900px) {
  .custom-page {
    .page-content{
      .image-wrapper{}
    }
    .media-wrapper{
      .media-item{
        width: calc(100% / 3);
      }
    }
  }
}
@media all and (max-width: 700px) {
  .custom-page {
    .page-content{
      .image-wrapper{}
    }
    .media-wrapper{
      .media-item{
        width: 50%;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .custom-page {
    .page-content{
      .image-wrapper{
        img{
          width: 100%;
        }
      }
    }
    .media-wrapper{
      .media-item{
        width: 100%;
      }
    }
  }
}
