@import "./mainColors";
$light-grey: #c9c6c6;
$borderColor: #D8D8D8;

.institution-card{
  display: flex;
  margin-bottom: 60px;
  .institution-card-img{
    width: 280px;

    .img-wrapper{
      width: 100%;
      padding-bottom: 80%;
      position: relative;
      img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
  }
  .institution-card-content{
    width: 60%;
    border-bottom: 1px solid $borderColor;
    margin-left: 60px;
    letter-spacing: 0.5px;
    .institution-card-name{
      font-size: 26px;
      font-family: Bold;
      margin-bottom: 15px;
      color: $titleColor;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .institution-card-description{
      height: 74px !important;
      overflow: hidden;
      font-size: 14px;
      line-height: 15px;
      white-space: nowrap;
      p{
        margin-bottom: 10px !important;
        span{
          font-size: 14px !important;
          line-height: 15px !important;
          white-space: nowrap;
        }
      }
    }
  }
}

@media all and (max-width: 1200px) {
  .institution-card{
    .institution-card-img{
      width: 280px;
    }
    .institution-card-content{
      margin-left: 30px;
      .institution-card-name{
        font-size: 22px;
        margin: 10px 0;
      }
      .institution-card-description{


      }
    }
  }
}
@media all and (max-width: 992px) {
  .institution-card{
    .institution-card-img{
      width: 240px;
    }
    .institution-card-content{
      width: 70%;
      margin-left: 20px;
      .institution-card-name{
        font-size: 18px;
        margin: 10px 0;
      }
      .institution-card-description{
        font-size: 12px;
        p{
          span{
            font-size: 12px !important;
          }
        }
      }
    }
  }
}
@media all and (max-width: 768px) {
  .institution-card{
    flex-direction: column;
    width: 100%;
    .institution-card-img{
      width: 100%;
    }
    .institution-card-content{
      width: 100%;
      margin-left: 0;
      padding-bottom: 10px;
      .institution-card-name{
        white-space: normal;
        font-size: 18px;
        margin: 10px 0;
      }
      .institution-card-description{
        width: 100%;
        height: 82px !important;
        p:first-child{
          white-space: normal;
        }
      }
    }
  }
}
