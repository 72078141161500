@import "../mainColors";
$totalItemsCount: 7;
$hideItemsCount: 5;
$dark: #272727;
$light-grey: #c9c6c6;
$cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);
.home-page-header {
  .header {
    width: 1600px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 145px;

    img {
      width: 100px;
      object-fit: cover;
      align-self: self-start;
      margin-top: 15px;
    }
    .header-inner {
      font-weight: bold;
      text-align: center;
      color: $dark;

      .header-text {
        font-size: 18px;
      }

      .header-title {
        font-size: 34px;
        margin-bottom: 0;
      }
    }
  }

  nav {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 2px #88888882;
    position: relative;
    width: 100%;
    height: 50px;

    ul {
      text-align: center;
      list-style-type: none;
      margin: 0;
      padding: 10px;
      display: flex;
      position: relative;

      .list-item {
        display: flex;
        align-items: center;
        font-family: "Fira Sans", sans-serif;
        font-size: 16px;
        padding: 0 14px;
        cursor: pointer;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        overflow: hidden;

        .active-link {
          a {
            color: blue;
          }
        }

        .menu-language {
          display: flex;
          align-items: center;
          color: #8e8e8e !important;

          .language-icon {
            width: 23px;
            height: 23px;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 5px;
          }
          span{
           margin-top: 2px;
          }
        }

        .ant-dropdown-link {
          color: $dark;
          font-size: 16px;

          img {
            margin-left: 5px;
          }
        }

        a {
          color: $dark;
        }

        &:hover {
          color: dodgerblue;
        }
      }

      @for $i from 1 through $totalItemsCount {
        li:nth-child(#{$i}) {
          &.is-closed {
            animation-delay: $i * 30ms;
            animation-name: specialFadeIn;
            animation-duration: 400ms;
            animation-fill-mode: both;
          }

          &.hidden {
            animation-delay: ($totalItemsCount - $i) * 30ms;
            animation-name: specialFadeOut;
            animation-duration: 400ms;
            animation-fill-mode: both;
          }
        }
      }

      .menu-language {
        font-size: 16px;
        display: flex;
        align-items: center;
        overflow: hidden;

        i {
          margin-left: 4px;
        }
      }
    }


    .menu-search-container {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      height: 100%;

      .menu-search-input {
        display: flex;
        flex-grow: 1;
        align-items: center;
        height: 100%;
        width: 90%;
        animation-name: specialFadeInRight;
        animation-duration: 400ms;
        animation-fill-mode: both;
        animation-delay: 350ms;

        input {
          height: 100%;
          background-color: transparent;
          border: none;
          flex-grow: 1;
          font-size: 16px;
          outline: none;
          margin-left: 10px;
        }
      }

      &.active {
        display: flex;
        animation-name: specialFadeOut;
        transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

        .menu-search-close {
          opacity: 1;
          visibility: visible;
        }
      }

      .menu-search-close {
        cursor: pointer;
        display: flex;
        align-items: center;
        animation-name: specialFadeInRight;
        animation-duration: 1000ms;
        animation-fill-mode: both;

        i {
          font-size: 22px;
          color: inherit;
          width: 100%;
        }
      }
    }


    .btn {
      display: none;
      position: absolute;
      right: 20px;
      background: transparent;
      height: 100%;
      transition: color 0.2s;
      //&.menu-is-open{
      //  color:#2D62FF
      //}
    }
  }

  .navMenu {
    position: absolute;
    z-index: 2;
    background: white;
    width: 100%;
    margin-top: 1px;
    display: none;

    .navigation-menu {
      width: 100%;
      box-shadow: 0px 0px 2px #88888882;
      overflow-x: hidden;

      .ant-menu-item > a {
        display: inline !important;
      }
    }
  }
}


.language-dropdown-item {
  img {
    width: 23px;
    height: 23px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 5px;
  }
}

@keyframes specialFadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
  }
}

@keyframes specialFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}


@keyframes specialFadeInRight {
  from {
    opacity: 0;
    transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@media all and (max-width: 1600px) {
  .home-page-header {
    .header {
      width: 90%;
    }
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1000px) {
  .home-page-header {
    nav {
      ul {
        .list-item {
          font-size: 12px;

          .ant-dropdown-link {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1000px) {
  .home-page-header {
    .header {
      .header-inner {
        .header-text {
          font-size: 14px;
        }

        .header-title {
          font-size: 22px;
          margin-bottom: 0;
        }
      }
    }

    nav {
      .btn {
        display: flex;
        align-items: center;
      }

      ul {
        width: 90%;
        justify-content: space-between;
        overflow: hidden;
        padding-left: 0;

        .list-item {
          display: none;
        }

        .search-icon {
          display: block;
          font-size: 15px;
          order: 2;
        }

        .language-selector {
          display: block;
          order: 1;
          padding-left: 0;

          .ant-dropdown-link {
            font-size: 15px;
          }
        }
      }

      .menu-search-container {
        .menu-search-close {
          i {
            margin-right: 20px;
          }
        }
      }
    }

    .navMenu {
      display: block;
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s ease-in, background 0.5s ease-in;

      &.menu-opened {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 195px);
        box-shadow: 0px 0px 2px #88888882;

        ul > li {
          transform: translateX(0px);
          opacity: 1;
        }

        ul {
          li:last-child {
            margin-bottom: 16px;
          }
        }

        button {
          width: 100%;
          height: 40px;
          background-color: $button-blue-color;
          color: white;
          font-family: Bold;
          font-size: 14px;
          letter-spacing: 0.5px;
          border-radius: 4px;
          margin-bottom: 10px;

          img {
            margin-left: 8px;
          }
        }
      }

      ul > li {
        transform: translateX(30px);
        transition: transform 0.5s $cubic, opacity 0.4s $cubic;
        opacity: 0;
      }
    }
  }
}

@media all and (max-width: 768px) {
  .home-page-header {
    .header {
      img {
        width: 50px;
      }

      .header-inner {
        .header-text {
          font-size: 13px;
        }

        .header-title {
          font-size: 20px;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media all and (max-width: 576px) {
  .home-page-header {
    .header {
      img {
        width: 40px;
      }

      .header-inner {
        .header-text {
          font-size: 13px;
        }

        .header-title {
          font-size: 18px;
        }
      }
    }

    nav {
      ul {
        width: 87%;
      }
    }
  }
}

@media all and (max-width: 400px) {
  .home-page-header {
    .header {
    }

    nav {
      ul {
        width: 86%;
      }
    }
  }
}
