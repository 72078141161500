@import '../mainColors';

.vacancies-page {
  width: 90%;
  margin: auto;
  min-height: calc(100vh - 195px);
  .vacancies-content{
    word-break: break-word;
  }
  .table {
    width: 100%;
    table {
      width: 100%;
      p {
        margin: 0;
      }
      thead {
        background-color: #F8F8F8;
        border: 1px solid #E5E5E6;
        border-radius: 15px 15px 0 0;
        tr {
          th {
            padding: 10px;
          }
        }
      }

      tr {
        width: 100%;

        td {
          padding: 10px;
          height: 100%;
          max-width: 33.2%;
          width: 33.2%;
          overflow: hidden;
          border: 1px solid $borderColor;

          span {
            word-break: break-word;
            display: inline-block;
            width: 100%;
            height: 100%;
            overflow: hidden;
          }
        }
      }
    }
  }
}

@media all and (max-width: 576px) {
  .vacancies-page {
    .category-filter{
      .category-item{
        text-align: center;
        font-size: 11px;
      }
    }
  }
}
