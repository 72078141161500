$dark:  #272727;
.home-page-slider {
  overflow: hidden;
  margin: 50px 0 0 5%;
  padding-bottom: 50px;
  position: relative;

  .slider-item {
    display: flex !important;
    padding-right: 20px;
    border-radius: 6px;
    position: relative;
    height: 500px;
    .slide-overlay{
      position: absolute;
      //top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: calc(100% - 20px);
      height: 120px;
      border-radius: 6px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
      //padding-right: 20px;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
    .slider-text{
      position: absolute;
      bottom: 0px;
      left: 20px;
      color: white;
      font-size: 16px;
      width: 80%;
      height: 48px;
      overflow: hidden;
      height: 50px;
      line-height: 25px;
      letter-spacing: 0.5px;
    }
  }

  .slider-arrows {
    display: flex;
    position: absolute;
    z-index: 1;

    .slider-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      border-radius: 50%;
      padding: 5px;
      width: 20px;
      height: 20px;

      img {
        width: 10px;
        height: 10px;
      }
    }

    .prev-btn {
      margin-right: 20px;
    }
  }

  .slider-numbers{
    width: 250px;
    margin: 40px auto;

    .slick-slider{
      .slick-list {
        height: 20px;
        .slick-track{
          color: $dark;
          .slider-dot-link{
            font-family: Medium;
          }
          .slick-current{
            color: black;
            font-size: 16px;
            + div{
              opacity: 0.5;
              + div{
                opacity: 0.2;
              }
            }
          }
        }
      }
    }
  }

  .slick-list {
    border-radius: 6px 0 0 6px;
  }
}


@media all and (min-width: 1500px) {
  .home-page-slider{
    margin-left: 150px;
    .slider-item {
      .slider-text{
        left: 30px;
        font-size: 20px;
        //height: 70px;
        //line-height: 20px;
      }
    }
  }
}
@media all and (max-width: 1500px) {
  .home-page-slider{
    .slider-item {
      height: 400px;
    }
    .slick-list {
      //height: 400px;
    }
  }
}
@media all and (max-width: 1200px) {
  .home-page-slider{
    .slider-item {
      height: 320px;
    }
  }
}
@media all and (max-width: 1000px) {
  .home-page-slider{
    //margin: 20px 0 0;
    .slider-item {
      height: 220px;
    }
    .slick-list {
      //border-radius: 0;
      .slide-overlay{
        //border-radius: 0;
      }
    }
  }
}

@media all and (max-width: 756px) {
  .home-page-slider{
    .slick-list {
      //height: unset;
    }
    .slider-arrows {
    }
  }
}


@media all and (max-width: 576px) {
  .home-page-slider{
    //margin: 20px 0 0 10px;
    .slider-item {
      height: 200px;
    }
    .slider-arrows{
      .prev-btn {
        margin-right: 10px;
      }
    }
    .slider-numbers{
      width: 160px;
      .slick-slider{
        //width: 220px;
        //margin-right: 50px;
      }
    }
  }
}