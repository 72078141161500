@import "../mainColors";
$light-grey: #c9c6c6;

.member-details {
  width: 90%;
  padding-top: 50px ;
  margin: auto;
  min-height: calc(100vh - 195px);

  .member-header {
    display: flex;
    align-items: flex-end;
    min-height: 200px;

    .img-wrapper{
      width: 570px;
      .img-content {
        position: relative;
        padding-bottom: 80%;

        img{
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          border-radius: 4px;
          position: absolute;
        }
      }
    }

    .wrapper{
      margin-left: 100px;

      .member-title {
        font-family: Bold;
        color: $titleColor;
        margin-bottom: 50px;
      }
      .member-profession{
        font-size: 21px;
        font-family: Regular;
        width: 70%;
        line-height: 30px;
      }
    }
  }

  .member-content {
    font-size: 15px;
    font-family: Regular;
    margin: 50px 0;
  }
}

@media all and (min-width: 1600px){
  .member-details{
    max-width: 1600px;
  }
}

@media all and (max-width: 1300px) {
  .member-details {
    .member-header {
      img {
        max-width: 470px;
        height: 300px;
      }
      .wrapper{
        margin-left: 80px;

        .member-title {
        }
        .member-profession{
          font-size: 18px;
          width: 85%;
        }
      }
    }
    .member-content {
      font-size: 14px;
    }
  }
}
@media all and (max-width: 1000px) {
  .member-details {
    .member-header {
      //img {
      //  max-width: 320px;
      //  height: 230px;
      //}
      .wrapper{
        margin-left: 40px;

        .member-title {
          margin-bottom: 35px;
          font-size: 22px;
        }
        .member-profession{
          font-size: 15px;
          width: 90%;
          line-height: 30px;
        }
      }
    }
  }
}
@media all and (max-width: 768px) {
  .member-details {
    .member-header {
      flex-wrap: wrap;
      img {
        max-width: 100%;
        //height: 300px;
      }
      .wrapper{
        margin-left: 0;
        margin-top: 30px;

        .member-title {
        }
        .member-profession{
          font-size: 16px;
          width: 70%;
          line-height: 30px;
        }
      }
    }
    .member-content {
      font-size: 13px;
    }
  }
}
@media all and (max-width:  576px) {
  .member-details {
    .member-header {
      img {
      }
      .wrapper{
        .member-title {
          margin-bottom: 35px;
          font-size: 22px;
        }
        .member-profession{
          font-size: 16px;
          width: 100%;
          line-height: 30px;
        }
      }
    }
    .member-content {
      font-size: 13px;
    }
  }
}
