$blue-color: #7295fe;

.contact-page {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 50px auto;

  .contact-form {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    width: 55%;

    .input-required {
      color: red;
      margin-left: 4px;
    }

    .form-top {
      display: flex;
      justify-content: space-between;

      .form-input-left, .form-input-right {
        width: 45%;

        .input-group {
          input {
            font-size: 15px;
            height: 55px;
          }

          label {
            font-size: 17px;
            color: black;
          }
        }
      }
    }

    .form-bottom {
      .input-group {
        textarea {
          font-size: 15px;
          height: 150px;
        }

        label {
          font-size: 17px;
          color: black;
        }
      }
    }

    .recaptcha-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .link-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #7295fe;
        color: white;
        font-family: Bold;
        font-size: 14px;
        width: 198px;
        //padding: 16px;
        border-radius: 6px;
        letter-spacing: 1.5px;
        height: 46px;
        //margin-top: 40px;


        .flex-row {
          display: flex;
          align-items: center;
          justify-content: center;
          i{
            svg{
              font-size: 20px;
            }
          }
          .button-text, .button-text-check{
            animation: open 1s ease;
            @keyframes open {
              from{
                opacity: 0;
              }
              to{opacity: 1}
            }
          }


          .ant-spin-dot {
            height: 18px;
          }

          .ant-spin {
            margin-left: 8px;
          }
        }
      }
    }
    .show-error{
      color: red;
      font-size: 12px;
    }
  }

  .contact-map {
    // width: 35%;
    width: 44%;

    h3 {
      color: black;
      font-family: Bold;
    }

    .map {
      width: 100%;
      height: 370px;
      background-color: #fff5e8;

      .marker {
        position: relative;

        img {
          position: absolute;
          width: 40px;
          height: 40px;
          top: -20px;
          left: -20px;
        }
      }
    }

    .map-info {
      margin-top: 40px;
      color: black;
      font-size: 15px;
      letter-spacing: 1px;
      font-family: Regular;

      .info-text {
        // font-size: 12px;
        span {
          font-family: Bold;
        }
      }
    }
  }
}

@media all and (min-width: 1600px) {
  .contact-page {
    max-width: 1600px;
  }
}

@media all and (max-width: 1200px) {
  .contact-page {
    .contact-form {
      width: 58%;

      .form-top {
        .form-input-left, .form-input-right {
          width: 48%;
        }
      }

      button {
        //margin-top: 20px;
      }
    }

    .contact-map {
      width: 38%;

      .map {
        width: 100%;
        height: 360px;
        background-color: #fff5e8;
      }
    }
  }
}

@media all and (max-width: 992px) {
  .contact-page {
    flex-direction: column;

    .contact-form {
      width: 100%;

      .form-top {
        .form-input-left, .form-input-right {
          width: 45%;
        }
      }
    }

    .contact-map {
      margin-top: 50px;
      width: 100%;
      display: flex;
      align-items: flex-end;

      .map-left {
        width: 60%;

        .map {
          width: 100%;
          height: 200px;
          background-color: #fff5e8;
        }
      }

      .map-info {
        margin-left: 50px;
      }
    }
  }
}

@media all and (max-width: 576px) {
  .contact-page {
    flex-direction: column;

    .contact-form {
      width: 100%;

      .form-top {
        flex-direction: column;

        .form-input-left, .form-input-right {
          width: 100%;
        }

      }
    }
    .recaptcha-wrapper{
      flex-direction: column;
      align-items: baseline !important;
      .link-button{
        order: 2;
        margin-top: 20px;
      }
    }
    .contact-map {
      margin-top: 50px;
      flex-direction: column;
      align-items: baseline;

      .map-left {
        width: 100%;
      }

      .map-info {
        margin-left: 0;
      }
    }
  }
}
