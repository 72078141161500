@import "../mainColors";

.search-page {
  width: 90%;
  margin: 50px auto;

  .search-header{
    position: relative;
  }
  .ant-dropdown-link {
    position: absolute;
    font-size: 20px;
    font-family: Medium;
    color: $titleColor;
    margin-bottom: 35px;
    padding-bottom: 7px;
    display: inline-block;
    height: 30px;
    top: 0;
    right: 0;

    i {
      margin-left: 10px;
    }
    img{
      margin-left: 10px;
    }
  }

  .search-content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    min-height: 500px;

    .no-content-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      img {
        width: 200px;
        height: 200px;
      }

      .text-message{
        font-size: 22px;
        font-family: Medium;
      }
    }
  }
}

@media all and (min-width: 1600px) {
  .search-page {
    max-width: 1600px;
  }
}

@media all and (max-width: 576px) {
  .search-page {
    .search-header{
      display: flex;
      flex-direction: column;
      .ant-dropdown-link{
        position: initial;
        //font-size: 22px;
      }
    }
    .search-content {
      justify-content: center;
    }
  }
}
