$dark: #272727;

.services{
  width: 90%;
  margin: 50px auto;
  min-height: calc(100vh - 195px);
  color: $dark;
  .services-content{
  }
}
@media all and (min-width: 1600px){
  .services{
    max-width: 1600px;
  }
}
@media all and (max-width: 576px) {
  .services{
    .services-content{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}