@import "../../mainColors";

.input-group {
  margin-bottom: 16px;
  padding: 0 2px;

  label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #677897;
    margin-bottom: 8px;
  }

  input, textarea, select {
    font-size: 13px;
    font-weight: 400;
    height: 40px;
    width: 100%;
    padding: 6px 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: all 0.2s;

    &:hover {
      border-color: $brandColor;
    }

    &:focus {
      outline: none;
      border-color: $brandColor;
      box-shadow: 0 0 0 2px rgba(31, 58, 98, 0.2);
    }

    &.invalid {
      border-color: red;
      outline: none;
    }


  }

  input[type="checkbox"] {
    height: 25px;
    width: 25px;
  }

  textarea {
    resize: none;
    height: 100px;
  }

  .multi-select-wrapper {
    position: relative;

    .select-header {
      font-size: 13px;
      font-weight: 400;
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 12px;
      border: 1px solid #ced4da;
      border-radius: 4px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      i {
        font-size: 20px;
        transition: all .2s ease;
      }
    }

    &.is-open {
      .select-header {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.select-options {
  width: 100%;
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  z-index: 50;

  .option-item {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    overflow: hidden;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;

    input {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    &:hover {
      background-color: #e6f7ff;
    }

    &.checked {
      color: rgba(0, 0, 0, 0.65);
      font-weight: 600;
      background-color: #fafafa;
    }
  }
}