@import "../mainColors";

$blue-color:#2D62FF;

.about {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 50px auto 0;

  .about-person {
    width: 65%;

    .about-header {
      display: flex;

      .about-header-img-content {
        .about-header-img {
          width: 400px;
          position: relative;
          padding-bottom: 80%;

          img {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            border-radius: 4px;
            position: absolute;
          }
        }
      }

      .about-header-inner {
        margin-left: 5%;
        width: calc(100% - 400px);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .about-name {
          color: $titleColor;
          white-space: nowrap;
          font-size: 30px;
          font-family: Bold;
        }

        .about-position {
          font-size: 18px;
          font-family: Bold;
          word-break: break-word;
          margin-bottom: 0;
        }

        .see-more{
          background: transparent;
          border: 1px solid black;
          border-radius: 25px;
          font-size: 16px;
          width: 210px;
          height: 44px;
          color: black;
          letter-spacing: 2px;
          img{
            margin-left: 10px;
          }
        }
      }
    }

    .about-description {
      margin: 30px 0;
      font-size: 16px;
      white-space: pre-wrap;
      font-family: Regular;
    }
  }

  .about-panel {
    width: 25%;

    .ant-collapse-item {
      position: relative;

      .collapse-item-wrapper {
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        left: 0;
      }
    }

    .hide {
      .ant-collapse-content {
        display: none !important;
      }
    }

    .ant-collapse-arrow {
      transition: all 0.2s;
      //width: 16px;
      transform: rotate(-90deg) !important;

      &.rotate {
        transform: translateY(-50%) rotate(0deg) !important;
      }
    }

    .ant-collapse {
      background-color: white;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      p {
        cursor: pointer;
        color: $blue-color;
        &:hover {
          color: $blue-color;
        }
      }
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding-left: 20px;
      color: $blue-color;
      font-family: Medium;
      &:hover {
        color: $blue-color;
      }
    }

    .empty {
      height: 50px;
      background-color: white;
      border-left: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
    }

    button {
      width: 100%;
      height: 45px;
      background-color: $blue-color;
      color: white;
      border-radius: 0 0 4px 4px;
      font-size: 15px;
      font-family: Bold;

      img {
        margin-left: 8px;
      }
    }
  }
}

@media all and (max-width: 1500px) {
  .about {
    .about-person {
      width: 60%;

      .about-header {
        .about-header-img-content {
          .about-header-img {
            width: 350px;
          }
        }
        .about-header-inner {
          width: calc(100% - 350px);
          .about-name{
            font-size: 25px;
          }
          .about-position{
            font-size: 16px;
          }
        }
      }
    }

    .about-panel {
      width: 30%;
    }
  }
}

@media all and (max-width: 1250px) {
  .about {
    .about-person {
      .about-header {
        .about-header-img-content {
          .about-header-img {
            width: 300px;
          }
        }
        .about-header-inner {
          margin-left: 2%;
          width: calc(100% - 300px);
          .about-name {
            font-size: 21px;
            margin-bottom: 5px;
          }

          .about-position {
            font-size: 13px;
          }

          .see-more {
            font-size: 13px;
            width: 170px;
            height: 40px;
          }
        }
      }
    }
    .about-panel {
      width: 28%;
    }
  }
}

@media all and (max-width: 1000px) {
  .about {
    .about-person {
      width: 100%;

      .about-header {
        .about-header-inner {
          width: 100%;

          .about-name {
            font-size: 24px;
          }

          .about-position {
            font-size: 14px;
          }

          .see-more {
            font-size: 14px;
            width: 190px;
          }
        }
      }
    }

    .about-panel {
      display: none;
    }
  }
}

@media all and (max-width: 768px) {
  .about {
    .about-person {
      width: 100%;

      .about-header {
        .about-header-img {
          width: 300px;
        }

        .about-header-inner {
          .about-name {
            font-size: 22px;
          }

          .about-position {
            font-size: 13px;
          }
        }
      }

      .about-description {
        font-size: 14px;
      }
    }
  }
}

@media all and (max-width: 700px) {
  .about {
    .about-person {
      .about-header {
        flex-direction: column;

        .about-header-img-content {
          .about-header-img{
            width: 100%;
          }
        }

        .about-header-inner {
          margin: 0;
          .about-name {
            margin-top: 15px;
            font-size: 18px;
          }

          .see-more {
            font-size: 12px;
            width: 170px;
          }
        }
      }

      .about-title {
        width: 100%;
        font-size: 17px;
      }
    }
  }
}
