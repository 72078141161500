@mixin custom-scrollbar-x {
  &::-webkit-scrollbar {
    height: 3px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: #adadad;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
    cursor: pointer;
  }
}

@mixin custom-scrollbar-y {
  &::-webkit-scrollbar {
    width: 3px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: #adadad;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
    cursor: pointer;
  }
}