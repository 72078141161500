.not-found-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 20px;
  min-height: calc(100vh - 195px);
  .inner-content {
    text-align: center;
    color: #121320;
    font-family: Bold;
    img{
      width: 60%;
    }
    .number {
      font-size: 15vw;
      line-height: 15vw;
      display: block;
    }
    .text-message {
      font-size: 2vw;
      //line-height: 15vw;
      display: block;
    }
    .lost-text {
      color: #0E0E10;
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
}

.admin-layout .not-found-page {
  background: #f2f7fb;
  width: calc(100% - 45px);
  -webkit-transition: width .2s linear;
  transition: width .2s linear;
  float: right;
  margin-top: 70px;
  min-height: calc(100vh - 70px);
  &.size-sm {
    width: calc(100% - 250px);
  }
}

@media screen and (max-width: 1500px) {
  .not-found-page {
    .inner-content .lost-text {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .not-found-page {
    .inner-content .lost-text {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .not-found-page {
    .inner-content {
      .number {
        font-size: 25vw;
        line-height: 25vw;
      }
      .lost-text {
        font-size: 20px;
      }
    }
  }

  .admin-layout .not-found-page {
    &.size-sm {
      width: calc(100% - 220px);
    }
  }
}

@media screen and (max-width: 520px) {
  .not-found-page {
    .inner-content {
      .lost-text {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .not-found-page {
    min-height: 50vh;
    .inner-content {
      .number {
        font-size: 30vw;
        line-height: 30vw;
      }
      .lost-text {
        font-size: 16px;
      }
    }
  }
}