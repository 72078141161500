@import "./mainColors";
$light-grey: #c9c6c6;

.video-card {
  width: calc(100% / 3);
  margin-bottom: 20px;

  .inner-part {
    margin-right: 15px;
  }

  .video-img-content {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    //height: 300px;
    .video-youtube {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .categories{
    height: 50px;
    overflow: hidden;
    margin: 10px 0;
    .chip {
      background-color:  $button-blue-color;
      color: white;
      border: 0;
      border-radius: 15px;
      padding: 8px 15px 7px 15px;
      font-size: 11px;
      margin: 15px 5px 15px 0;
      display: inline-block;
      white-space: nowrap;
    }
  }

  .card-text {
    font-size: 17px;
    font-family: Medium;
    letter-spacing: 0.5px;
    color: $titleColor;
    width: 70%;
    margin-bottom: 12px;
    line-height: 20px;
    height: 40px;
    overflow: hidden;
  }
}

@media all and (max-width: 1300px) {
  .video-card {
    .card-text {
      width: 70%;
      font-size: 15px;
      height: 40px;
      line-height: 20px;
      overflow: hidden;
    }
  }
}

@media all and (max-width: 1000px) {
  .video-card {
    //width: 31%;

    .video-img-content {
      //height: 200px;
    }

    .card-text {
      width: 100%;
    }
  }
}

@media all and (max-width: 768px) {
  .video-card {
    width: 50%;

    .card-text {
      width: 100%;
    }
  }
}

@media all and (max-width: 576px) {
  .video-card {
    width: 400px;
  }
}

@media all and (max-width: 400px) {

}
