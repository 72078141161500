$blue-color: #7295fe;
$light-grey: #c9c6c6;

.layout-footer {
  .top {
    background-color: #1b1b30;
    color: white;
    padding: 3rem 0;

    .content {
      display: flex;
      position: relative; //
      // justify-content: space-between;//
      width: 90%;
      margin: auto;

      .img {
        display: flex;
        // align-items: center;//

        img {
          height: 100px;
        }
      }

      .content-inner {
        display: flex;
        justify-content: space-between;

        // width: 70%; //
        ul {
          list-style-type: none;
          line-height: 25px;
          font-size: 15px;

          li:first-child,
          li:first-child a {
            font-size: 1.6rem;
            padding-bottom: 1rem;
          }

          li,
          li a {
            font-family: Medium;
            color: white;
          }

          li {
            font-size: 14px; //
            text-wrap: nowrap; //

            @media screen and (max-width: 600px) {
              text-wrap: wrap;
            }
          }

          .footer-span {
            font-size: 13px; //
            color: #b1b1b8;
            padding-right: 5px;
            font-family: Regular;
          }
        }
      }

      .social {
        display: flex;
        align-items: flex-end;
        padding-bottom: 1rem;
        position: absolute;//
        right: 0;//
        top: 0; //

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: $blue-color;
          margin-right: 10px;

          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  .bottom {
    background-color: rgb(18, 19, 32);
    color: white;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      margin: auto;

      .left {
        width: 28%;
      }

      .right {
        font-size: 13px;

        img {
          padding-left: 10px;
        }
      }
    }
  }
}

@media all and (min-width: 1600px) {
  .layout-footer {

    .top .content,
    .bottom .content {
      max-width: 1600px;
    }
  }
}

@media all and (max-width: 1300px) {
  .layout-footer {
    .top {
      .content {
        .content-inner {
          ul {
            font-size: 13px;

            li:first-child,
            li:first-child a {
              font-size: 20px;
            }
          }
        }
      }
    }

    .bottom {
      .content {
        .left {
          width: 40%;
        }

        .right {
          img {}
        }
      }
    }
  }
}

// @media all and (max-width: 1100px) {
@media all and (max-width: 1327px) {
  .layout-footer {
    .top {
      .content {
        flex-wrap: wrap;
        justify-content: start;

        .img {
          order: 1;
          width: 35.5%;
        }

        .content-inner {
          order: 3;
          flex-wrap: wrap;
          width: 100%;

          ul {
            padding-top: 15px;
          }

          .footer-home-page,
          .footer-news {
            width: 30%;
          }
        }

        .social {
          position: relative;
          order: 2;
          padding-left: 0;
        }
      }
    }

    .bottom {
      .content {
        flex-direction: column;
        justify-content: center;
        text-align: center;

        .left {
          width: 100%;
          font-size: 13px;
        }

        .right {
          font-size: 12px;
        }
      }
    }
  }
}

@media all and (max-width: 900px) {
  .layout-footer {
    .top {
      .content {
        .img {
          width: 50%;
        }

        .content-inner {

          .footer-home-page,
          .footer-news {
            width: 50%;
          }
        }

        .social {
          order: 2;
          padding-left: 0;
        }
      }
    }
  }
}

@media all and (max-width: 550px) {
  .layout-footer {
    .top {
      .content {
        .content-inner {

          .footer-home-page,
          .footer-news {
            width: 100%;
          }
        }
      }
    }
  }
}