@font-face {
  font-family: Bold ;
  src: url(../fonts/Mardoto-Bold.ttf);
}

@font-face {
  font-family: Black ;
  src: url(../fonts/Mardoto-Black.ttf);
}
@font-face {
  font-family: Medium ;
  src: url(../fonts/Mardoto-Medium.ttf);
}
@font-face {
  font-family: Regular ;
  src: url(../fonts/Mardoto-Regular.ttf);
}
@font-face {
  font-family: Ligh ;
  src: url(../fonts/Mardoto-Light.ttf);
}
@font-face {
  font-family: Thin ;
  src: url(../fonts/Mardoto-Thin.ttf);
}
