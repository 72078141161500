$blue-color: #7295fe;

.category-slider{
  .category-item {
    margin-right: 10px;
    font-size: 16px;
    border-radius: 25px;
    padding: 8px 20px;
    transition: all 0.2s;
    text-align: center;
    text-overflow: ellipsis;
  }
  .selected{
    background-color: $blue-color;
    color: white;

  }
  .category-arrows {
    display: flex;
    position: absolute;
    right: 6%;
    z-index: 1;
    margin-top: 10px;

    .slider-btn-img {
        width: 12px;
    }

    .prev-btn {
      margin-right: 30px;
    }
  }
}

@media all and (min-width: 1700px) {
  .category-slider{
    .category-arrows{
      right: 9%;
    }
  }
}
@media all and (max-width: 1200px) {
  .category-slider{
    .category-item {
      font-size: 14px;
    }
  }
}
@media all and (max-width: 992px) {
  .category-slider{
    .category-item {
      font-size: 13px;
      padding: 6px 17px;
    }
  }
}
@media all and (max-width: 576px) {
  .category-slider{
    .category-item {
      font-size: 12px;
      padding: 5px 15px;
      margin-right: 0;
    }
  }
}