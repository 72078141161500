$brandColor: #FF3F62;

.service-list-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 60px;
  width: 100%;
  background: white;
  border-radius: 3px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  box-shadow: 0 2px 4px 0 rgba(125, 151, 168, 0.14);
  &:hover {
    box-shadow: 0 6px 12px 0 rgba(125, 151, 168, 0.14);
  }
  span {
    color: #3B3943;
    font-size: 16px;
    font-weight: 500;
    width: calc(100% - 18px);
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  img {
    width: 14px;
    height: 14px;
  }
}

.prev-lg, .next-lg {
  width: 25px;
  padding: 0;
  background: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  i {
    color: #333E48;
  }
  &.passive {
    cursor: default;
    i {
      color: #9e9e9e;
    }
  }
}

.prev-sm, .next-sm {
  background: $brandColor;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  -webkit-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -ms-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear;
  &:hover {
    box-shadow: 0 4px 4px 0 rgba(109, 134, 163, 0.25);
  }
  i {
    color: white;
  }
  &.passive {
    opacity: 0;
    visibility: hidden;
  }
}

.see-more {
  border: 1px solid $brandColor;
  background: none;
  color: $brandColor;
  height: 46px;
  width: auto;
  font-size: 15px;
  white-space: nowrap;
  border-radius: 4px;
  a {
    width: 100%;
    height: 100%;
    color: inherit;
    display: flex;
    padding: 0 40px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}

.to-top-button {
  background: $brandColor;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s linear, box-shadow .3s ease;
  opacity: 0;
  z-index: 50;
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
  box-shadow: 0 2px 4px 0 rgba(109, 134, 163, 0.25);
  &:hover {
    box-shadow: 0 4px 4px 0 rgba(109, 134, 163, 0.25);
  }
  &.show {
    opacity: 1;
  }
  i {
    color: white;
  }
}

.list-point {
  border: 1px solid $brandColor;
  border-radius: 4px;
  position: relative;
  width: 28px;
  height: 28px;
  .floating-inner-point {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $brandColor;
    transform: translate(-50%, -50%);
  }
}

.hamburger-btn {
  position: absolute;
  top: 6px;
  right: 20px;
  width: 40px;
  height: 40px;
  z-index: 999;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  transition: -webkit-transform .2s cubic-bezier(.23, 1, .32, 1);
  transition: transform .2s cubic-bezier(.23, 1, .32, 1);
  transition: transform .2s cubic-bezier(.23, 1, .32, 1), -webkit-transform .2s cubic-bezier(.23, 1, .32, 1);
  outline: 0;
  &:before, &:after {
    content: "";
    position: relative;
    -webkit-transform-origin: center;
    transform-origin: center;
    display: block;
    height: 2px;
    background-color: #000;
    transition: all .25s ease;
  }
  &:before {
    width: 40px;
  }
  &:after {
    top: 7px;
    width: 30px;
  }
  &.is-open {
    top: 10px;
    position: fixed;
    &:before, &:after {
      width: 30px;
      background-color: #fff;
    }
    &:before {
      transform: rotate(45deg) translate(4px, -2px);
    }
    &:after {
      transform: rotate(-45deg) translate(8px, -3px);
    }
  }
}

.form-input {
  border: none;
  border-bottom: 1px solid #A1A5A9;
  background: none;
  color: #A1A5A9;
  transition: all .2s linear;
  padding-bottom: 22px;
  &:focus {
    outline: none;
    border-bottom: 1px solid #424043;
    color: #424043;
  }
  &.invalid {
    border-bottom: 1px solid #ff002e;
  }
}

@media screen and (max-width: 768px) {
  .service-list-button {
    padding: 0 25px;
    height: 50px;
    span {
      font-size: 14px;
    }
  }

  .see-more {
    font-size: 14px;
    a {
      padding: 0 25px;
    }
  }
}

@media screen and (max-width: 560px) {
  .prev-sm, .next-sm {
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
  .next-sm {
    right: 0;
    left: unset;
  }

  .hamburger-btn {
    top: 8px;
    &:before {
      width: 30px;
    }
    &:after {
      top: 6px;
      width: 20px;
    }
    &.is-open {
      top: 12px;
      &:before {
        transform: rotate(45deg) translate(2px, -2px);
      }
      &:after {
        transform: rotate(-45deg) translate(8px, -4px);
      }
    }
  }

  .to-top-button {
    width: 35px;
    height: 35px;
    bottom: 35px;
    right: 35px;
  }
}

@media screen and (max-width: 380px) {
  .prev-sm, .next-sm {
    width: 20px;
    height: 20px;
    i {
      font-size: 18px;
    }
  }

  .form-input {
    padding-bottom: 12px;
    font-size: 12px;
  }

  .service-list-button {
    padding: 0 15px;
    height: 45px;
  }

  .see-more {
    font-size: 13px;
    height: 36px;
    a {
      padding: 0 20px;
    }
  }

  .hamburger-btn {
    top: 4px;
    width: 25px;
    right: 15px;
    &:before {
      width: 25px;
    }
    &:after {
      top: 6px;
      width: 15px;
    }
    &.is-open {
      top: 7px;
      &:before {
        width: 25px;
        transform: rotate(45deg) translate(2px, -2px);
      }
      &:after {
        width: 25px;
        transform: rotate(-45deg) translate(8px, -4px);
      }
    }
  }
}