$light-grey: #c9c6c6;
$borderColor: #D8D8D8;
$dark: #272727;

.member-card{
  display: flex;
  margin-bottom: 60px;
  .member-card-img{
    width: 280px;
    .img-wrapper{
      position: relative;
      width: 100%;
      padding-bottom: 80%;
      img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
      }
    }
  }
  .member-card-content{
    width: 60%;
    border-bottom: 1px solid $borderColor;
    margin-left: 60px;
    letter-spacing: 0.5px;
    a{
      color: $dark;
    }
    .member-card-name{
      font-size: 28px;
      font-family: Bold;
      margin-bottom: 15px;
    }
    .member-card-profession{
      font-size: 18px;
      line-height: 30px;
      height: 66px;
      overflow: hidden;
    }
    .member-card-text{
      font-size: 14px;
      line-height: 20px;
      height: 62px;
      overflow: hidden;
    }
  }
}

@media all and (max-width: 1200px) {
  .member-card{
    .member-card-img{
      width: 260px;
    }
    .member-card-content{
      width: 80%;
      margin-left: 50px;
      .member-card-name{
        //font-size: 25px;
        margin-bottom: 10px;
      }
      .member-card-text{
        //font-size: 16px;
        //line-height: 28px;
      }
    }
  }
}
@media all and (max-width: 992px) {
  .member-card{
    .member-card-img{
      width: 240px;
    }
    .member-card-content{
      width: 70%;
      margin-left: 40px;
      .member-card-name{
        font-size: 20px;
      }
      .member-card-text{
        font-size: 13px;
        //line-height: 25px;
      }
    }
  }
}
@media all and (max-width: 768px) {
  .member-card{
    .member-card-img{
      width: 220px;
    }
    .member-card-content{
      //width: 100%;
      margin-left: 30px;
      .member-card-name{
        //font-size: 17px;
        margin: 5px 0;
      }
      .member-card-text{
        //font-size: 12px;
        //line-height: 19px;
      }
    }
  }
}
@media all and (max-width: 650px) {
  .member-card{
    flex-direction: column;
    .member-card-img{
      width: 100%;
    }
    .member-card-content{
      width: 100%;
      margin-left: 0;
      padding-bottom: 12px;
      .member-card-name{
        //font-size: 20px;
        margin: 10px 0;
      }
      .member-card-text{
        //font-size: 15px;
        //line-height: 25px;
      }
    }
  }
}
