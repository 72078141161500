@import "../mainColors";

.gallery{
  width: 100%;
  margin: 50px auto;

  .title{
    color: $titleColor;
    font-size: 30px;
    font-family: Bold;
    margin-bottom: 10px;
  }
  .link{
    font-size: 18px;
    cursor: pointer;
    color: $titleColor;
    display: inline-block;
    img{
      margin-left: 8px;
    }
  }
  .content{
    display: flex;
    //justify-content: space-between;
  }
}
@media all and (max-width: 768px) and (min-width: 576px) {
  .gallery{
    .content{
      height: 300px;
      overflow: hidden;
      .video-card{
        margin-bottom: 50px;
      }
    }
  }
}
@media all and (max-width: 768px) {
  .gallery{
    .content{
      flex-wrap: wrap;
    }
  }
}
@media all and (max-width: 576px) {
  .gallery{
    .content{
      justify-content: center;
    }
  }
}